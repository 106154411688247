import { Modal } from 'bootstrap'
import Matrixator from './martrixator.js'

const chars = 'ASKI aski ASKI aski'

document.addEventListener("DOMContentLoaded", function() {

  const matrixator = new Matrixator('canvas', chars, '10')

  matrixator.run()
})