export default class Matrixator {
  constructor(canvas, chars, fontSize) {
    this.fontSize = fontSize
    this.canvas = document.querySelector(canvas)
    
    this.characters = chars.split('');
    this.drops = []
  }

  defineSize() {
    this.canvas.width = window.innerWidth
    this.canvas.height = window.innerHeight
  }

  calculateColumns() {
    this.columns = Math.ceil(this.canvas.width / this.fontSize)
  }

  defineDrops() {
    for (let i = 0; i < this.columns; i++) {
      // this.drops[i] = 1
      this.drops[i] = Math.round(Math.random())
    }
  }

  draw() {
    this.ctx = this.canvas.getContext('2d')
    this.ctx.fillStyle = 'rgba(89, 68, 62, .2)'
    this.ctx.fillRect(0,0, this.canvas.width, this.canvas.height)

    for (let i = 0; i < this.drops.length; i++) {
      this.text = this.characters[Math.floor(Math.random() * this.characters.length)]

      this.ctx.fillStyle = '#a68C83'
      this.ctx.fillText(this.text, i * this.fontSize, this.drops[i] * this.fontSize)
      this.drops[i]++

      if (this.drops[i] * this.fontSize > this.canvas.height && Math.random() > .85) {
        this.drops[i] = 0
      }
    }
  }

  run() {
    this.defineSize()
    this.calculateColumns()
    this.defineDrops()

    console.log(this)

    setInterval(() => this.draw(), 75) // default 33
  }
}